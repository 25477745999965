import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { OrderDetailsService } from '../../services/orderDetails.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-order-information',
  templateUrl: './order-information.component.html',
  styleUrl: './order-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderInformationComponent implements OnInit, OnDestroy {
  orderDetailsarray: any;
  public orderInformation: Observable<any>;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private orderService: OrderDetailsService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.globalService.loadingSubject.next(true);
    this.orderInformation = this.orderService.OrderListData$;
    this.orderService.OrderListData$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data) => {
    });
  }
  get isError() {
    return this.orderService.orderErrorFlag;
  }

  getUpdatedDate(data: any) {
    if (!!data) {
      const dateFormat = this.storageService.dateFormat;
      const parsedDate = Date.parse(data);
      if (!!dateFormat) {
        return this.changeDateFormat(
          new Date(parsedDate).toLocaleDateString('en-US'),
          dateFormat.replace(/"/g, '')
        );
      } else {
        return new Date(parsedDate).toLocaleDateString('en-US');
      }
    } else {
      return '-';
    }
  }

  changeDateFormat(formatDate, dateFormat) {
    try {
      return this.datePipe.transform(formatDate, dateFormat);
    } catch (error) {
      console.error('Date format error:', error);
      return formatDate;
    }
  }

  findSymbol(currency) {
    let currencies = ['EUR', 'DKK', 'USD', 'GBP', 'CAD'];
    let symbols = ['€', 'DKK', '$', 'GBP', 'CAD'];
    return symbols.at(currencies.indexOf(currency));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
